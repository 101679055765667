// TourGuide.js
import React from 'react';
import Tour from 'reactour';
import { useTranslation } from 'react-i18next';
import draagstructuur01 from '../../images/draagstructuur01.png';

const VisualHelper = ({ isOpen, onRequestClose }) => {
    const { t } = useTranslation();

    const steps = [
        {
            selector: '',
            content: t('tour.step1')
        },
        {
            selector: '#units',
            content: t('tour.step2'),
        },
        {
            selector: '#width',
            content: (
                <>
                    <p>{t('tour.step3')}</p>
                    <span className='font-bold text-xs'>{t('disclaimerWidth')}</span>
                    <img src={draagstructuur01} alt='draagstructuur' />
                </>
            ),
            style: { width: '600px', maxWidth: 'calc(100% - 20px)' }
        },
        {
            selector: '#height',
            content: t('tour.step4'),
        },
        {
            selector: '#add-wall',
            content: t('tour.step6'),
        },
    ];
    return (
        <Tour
            steps={steps}
            isOpen={isOpen}
            startAt={0}
            onRequestClose={onRequestClose}
            rounded={5}
            accentColor="#007bff"
            disableInteraction={true}
            closeWithMask={true}
            style={{maxWidth: '5px'}}
        />
    );
};

export default VisualHelper;