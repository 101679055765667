import logo from '../../images/logo.png'
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const languages = [
    { code: 'be', name: 'Nederlands', flag: '🇳🇱🇧🇪'},
    { code: 'fr', name: 'français', flag: '🇫🇷🇧🇪' },
    { code: 'en', name: 'English', flag: '🇬🇧🇺🇸' },
    { code: 'es', name: 'Spanish', flag: '🇪🇸' },
    // Add more languages as needed
];
export default function Header({onLanguageChange}) {
    const { t, i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const handleLanguageChange = (language) => {
        onLanguageChange(language.code);
        toggleDropdown();
        // Add your logic for language change here, e.g., updating the language in your app
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    return (
        <header className="App-header p-2">
            <div className="flex language-picker">
                <div className="ml-auto flex">
                    <div className="items-center">
                        <div>
                            <button
                                type="button"
                                onClick={toggleDropdown}
                                className="items-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                            >
                                {t('flagEmoji')} {t('languageName')}
                            </button>
                        </div>

                        {isOpen && (
                            <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                                <div className="py-1">
                                    {languages.map((language) => (
                                        <button
                                            key={language.code}
                                            onClick={() => handleLanguageChange(language)}
                                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                                        >
                                            {language.flag} {t(language.name)}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className='flex'>
                <img src={logo} className="App-logo object-contain mx-auto mt-10 max-w-xs w-full" alt="logo" style={{"width": "165px"}} />
            </div>
        </header>
    )
};